<template>
     <div class="container-fluid">
         <SuccessAlert ref="MySuccessAlert" :title="AlertTitle" :message="AlertMessage" :back="true"/>
          <div class="row row-custom">
            <div class="col-md-8">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title">اضافة تصنيف فرعي</h4>
                 </div>
                <div class="card-body">
                    <div class="row mb-3">
                        <Input id="name" title="الاسم بالعربي" @Input="HandleNameInput('ar',$event)" Mykey="name.ar" :errors="service_error" :MyValue="form.name.ar"/>
                        <Input id="area" title="الاسم بالانجليزي" @Input="HandleNameInput('en',$event)" Mykey="name.en" :errors="service_error" :MyValue="form.name.en"/>
                        <InputFile id="img" title="الصورة" @Input="HandleInput('img',$event)" Mykey="img"  :errors="service_error" />
                        <Input id="offline_id" title="رقم التصنيف الفرعي" @Input="HandleInput('offline_id',$event)" Mykey="offline_id" :errors="service_error" :MyValue="form.offline_id"/>
           
                    </div>

                    <button type="button" class="btn btn-primary pull-right" v-if="isNew"  @click="onSubmit()">حفظ </button>
                    <button type="button" class="btn btn-primary pull-right" v-else  @click="onUpdate()">تعديل </button>
                     <div class="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import Input from '../../../../components/Form/TextInput'
 import InputFile from '../../../../components/Form/InputFile'
  import {mapState,mapMutations} from 'vuex';
import store from '../../../../store/index'
import SuccessAlert from '../../../../components/SuccessAlert'
export default {
  computed:{
    ...mapState({
        SubCategory:state=>state.admin.subcategories.SubCategory.data,
        service_error:state=>state.admin.subcategories.SubCategory.errors,

    }),

    isNew(){
      return this.form.sub_category_uuid == '';
    },
 
   },
   data(){
       return{
           form:{
               sub_category_uuid:'',
               uuid:'',//category
               name:{
                   ar:'',
                   en:''
               },
               img:'',
               offline_id:'',
           },
           AlertTitle:'',
           AlertMessage:'',
 
       }
   },
   methods:{
    ...mapMutations({
          cleanErrors: "admin/cleanCategoriesErrors"
       }),
     setData(vm){
            vm.form.sub_category_uuid=vm.SubCategory.uuid;
            vm.form.name.ar=vm.SubCategory.name.ar;
            vm.form.name.en=vm.SubCategory.name.en;
            vm.form.offline_id=vm.SubCategory.offline_id;
        },
       
       onSubmit(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/storeSubCategory',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
       onUpdate(){
     //    this.isLoading=true;
          store.commit('admin/PleaseStartLoading');        
          let formData=new FormData(); 
          for (let key in this.form) {
            if(key == 'name'){
              formData.append('name[ar]', this.form[key].ar);
              formData.append('name[en]', this.form[key].en);
            }else{  
            formData.append(key, this.form[key]);
            }
          }
          formData.append('_method', "patch");
          store.commit('admin/PleaseStartLoading');
          store.dispatch('admin/updateSubCategory',formData).then((response)=>{
             store.commit('admin/PleaseStopLoading');
            this.cleanErrors();
            this.AlertTitle="معلومات",
            this.AlertMessage="تم حفظ التغيرات بنجاح";
            this.$refs.MySuccessAlert.showModel();
          }).catch((error)=>{
          store.commit('admin/PleaseStopLoading');
          });
       },
  
     HandleInput(key,value){
         this.form[key]=value;
     },
     HandleNameInput(key,value){
         this.form['name'][key]=value;
     },
     HandleSelect(key,value){
         this.form[key]=value;
     },
   },
   components:{
       Input,
       SuccessAlert,
       InputFile,
    },
   
     beforeRouteEnter(to,from,next){  
      //   store.commit('admin/PleaseStartLoading');
         const sub_category_uuid = to.params.sub_category_uuid; 
         const category_uuid = to.params.category_uuid; 
        if (typeof sub_category_uuid === "undefined") {
            next(vm => {
                vm.form.uuid=category_uuid;
       //       store.commit('admin/PleaseStopLoading');
              //  vm.isLoading = false;
            });
         } else {
             store.dispatch('admin/fetchSubCategory', {
                 sub_category_uuid:sub_category_uuid,
                 category_uuid:category_uuid

             }).then((response) => {
                next(vm => {
                    vm.setData(vm);
                     vm.form.uuid=category_uuid;
         //           store.commit('admin/PleaseStopLoading');
               //     vm.isLoading = false;
                });


            });
        }
    },
      beforeDestroy(){
         this.cleanErrors();
     }
}
</script>

<style lang="scss">
/*
 *
 */
.row-custom{
    justify-content: center;
}


















</style>